import CtaButton from "components/atoms/CtaButton";
import Icon from "components/atoms/Icon";
import Badge from "components/atoms/Badge";
import React from "react";
import CommentIcon from "public/icons/comment.svg";
import {
  GLOBAL_COMMENT,
  useGetComments,
  useGetCommentsViewStatus,
  useSetCommentViewStatus,
} from "hooks/comments";
import { useMemo } from "react";
import { useState } from "react";
import CommentsDrawer from "./CommentsDrawer";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import cn from "classnames";
import { Comment } from "models/Comment";
import { useTeam } from "contexts/TeamContext";
import { useSetCurrentTeam } from "hooks/teams";
import { useRouter } from "next/router";
import { useQueryClient } from "react-query";
import { useUser } from "contexts/UserContext";
import { useCommentManagement } from "hooks/useCommentMenagement";
import useTranslation from "next-translate/useTranslation";
import { useCommentsFiltersState } from "hooks/useCommentsFiltersState";
import { usePortal } from "contexts/PortalContext";

interface ICommentsButtonProps {
  className?: string;
  iconSize?: number;
  iconClassName?: string;
  headerHeight?: number;
  commentsfilter?: Record<string, any>;
  commentsStatusFilter?: Record<string, any>;
  drawerStateHandler?: (val: boolean) => void;
  drawerDescriptionComponent?: React.ReactNode;
}

const CommentsButton: React.FC<ICommentsButtonProps> = ({
  className,
  iconSize = 20,
  iconClassName,
  commentsfilter = {},
  headerHeight,
  commentsStatusFilter = {},
  drawerStateHandler,
  drawerDescriptionComponent,
}) => {
  const { t } = useTranslation("common");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const { currentTeam, refetchTeam, unitedMembers } = useTeam();
  const { user, userToken } = useUser();
  const { portalName } = usePortal();
  const router = useRouter();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (drawerStateHandler) {
      drawerStateHandler(openDrawer);
    }
  }, [drawerStateHandler, openDrawer]);

  const {
    filter,
    filterTabs,
    activeFilterTab,
    onFilterTabChange,
    appliedFilters,
    onFilterChange,
    searchValue,
    onSearchChange,
    debouncedSearchValue,
    sort,
    appliedSort,
    onSortChange,
  } = useCommentsFiltersState();

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchComments,
  } = useGetComments(
    {
      ...commentsfilter,
      ...appliedFilters,
      ...appliedSort,
      ...activeFilterTab,
      search: debouncedSearchValue,
      limit: 20,
      "filter.repliedToId": !!debouncedSearchValue ? undefined : "$null",
    },
    {
      enabled: !!user && !!userToken,
      keepPreviousData: true,
    }
  );

  const { data: commentsViewStatus, refetch } = useGetCommentsViewStatus(
    commentsStatusFilter,
    {
      enabled: !!user && !!userToken,
    }
  );

  const refetchData = () => {
    refetchComments();
    refetch();
  };

  const { mutate: markAsRead } = useSetCommentViewStatus({
    onSuccess() {
      refetchData();
    },
  });

  const { mutate: setCurrentTeam } = useSetCurrentTeam();

  const { ref, inView } = useInView();

  const preparedComments = useMemo(() => {
    if (data && data.pages.length) {
      return data.pages.reduce((acc, el) => acc?.concat(el?.data), []);
    }

    return [];
  }, [data]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, inView]);

  const moveToPath = (path: string) => {
    if (path !== router.asPath) {
      router.push(path);
    }
  };

  const onCommentClick = (comment: Comment) => {
    const path = `/${portalName + comment.resourcePath}`;
    queryClient.setQueryData(GLOBAL_COMMENT, comment);
    markAsRead(comment.id);
    if (comment.teamId !== currentTeam?.id) {
      setCurrentTeam(comment.teamId, {
        onSuccess() {
          refetchTeam();
          moveToPath(path);
        },
      });
    } else {
      moveToPath(path);
    }
  };

  const {
    deleteCandidate,
    editCandidate,
    setEditCandidate,
    closeRemoveModal,
    onRemoveComment,
    editLoading,
    removeLoading,
    onEditComment,
    onConfirmRemoving,
    onMarkAsRead,
    onChangeStatus,
  } = useCommentManagement(refetchData);

  return (
    <>
      <CtaButton
        onClick={() => setOpenDrawer((s) => !s)}
        variant="text"
        className={cn("relative", className)}
      >
        <Icon
          width={iconSize}
          height={iconSize}
          component={CommentIcon}
          viewBox="0 0 512 512"
          className={cn("group-hover:fill-gray-950", iconClassName)}
        />
        {!!commentsViewStatus?.unreadComments && (
          <Badge
            className={cn(
              "absolute top-0 -right-1",
              !!commentsViewStatus?.unreadMentions && "!bg-blue-500"
            )}
          />
        )}
      </CtaButton>
      <CommentsDrawer
        descriptionComponent={drawerDescriptionComponent}
        filterTabs={filterTabs}
        onFilterTabChange={onFilterTabChange}
        activeFilterTab={activeFilterTab}
        filter={filter}
        sort={sort}
        search={searchValue}
        onSortChange={onSortChange}
        onSearchChange={onSearchChange}
        onFilterChange={onFilterChange}
        listEndRef={ref}
        loading={isFetchingNextPage}
        comments={preparedComments}
        isOpen={openDrawer}
        onCommentClick={onCommentClick}
        onEdit={setEditCandidate}
        onRemove={onConfirmRemoving}
        onMarkAsRead={onMarkAsRead}
        onChangeStatus={onChangeStatus}
        onSubmitEdditing={onEditComment}
        editCandidate={editCandidate}
        editLoading={editLoading}
        selectedComment={null}
        onClose={() => setOpenDrawer(false)}
        refetchComments={refetchComments}
        members={unitedMembers}
        headerHeight={headerHeight}
      />
    </>
  );
};

export default CommentsButton;
