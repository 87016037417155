import { NextRouter } from "next/router";
import { CommentPage, CommentSubject } from "utils/constants";
import has from "lodash/has";

const getTrendsFilters = (_: NextRouter) => ({
  "filter.pageName": CommentPage.TRENDS,
});

const getProjectListFilters = (_: NextRouter) => ({
  "filter.pageName": CommentPage.PROJECT,
  "filter.subject": CommentSubject.PROJECT,
});

const getTvProjectListFilters = (_: NextRouter) => ({
  "filter.pageName": CommentPage.TV,
  "filter.subject": CommentSubject.TV_PROJECT,
});

const getTvProjectFilters = (router: NextRouter) => {
  const { tvProjectId } = router.query;
  return { "filter.tvProjectId": tvProjectId };
};

const getProjectFilters = (router: NextRouter) => {
  const { projectId } = router.query;
  return { "filter.projectId": projectId };
};

const filters = {
  "/[portalName]/[teamId]/trend-dashboard": getTrendsFilters,
  "/[portalName]/[teamId]/analytics": getProjectListFilters,
  "/[portalName]/[teamId]/analytics/[projectId]": getProjectFilters,
  "/[portalName]/[teamId]/ai-design": getTvProjectListFilters,
  "/[portalName]/[teamId]/ai-design/[tvProjectId]/[channelId]":
    getTvProjectFilters,
};

export const getCommentsTabFilters = (router: NextRouter) => {
  if (has(filters, router.pathname)) {
    return filters[router.pathname as keyof typeof filters](router);
  }

  return {};
};
