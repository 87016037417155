import { TeamRoleEnum } from "types";

export const checkRole = (
  currentRole: TeamRoleEnum | undefined,
  allowedRoles: TeamRoleEnum[]
) => {
  if (!currentRole) {
    return false;
  }
  return [...allowedRoles, TeamRoleEnum.owner, TeamRoleEnum.admin].includes(
    currentRole
  );
};
