import React, { Fragment } from "react";
import cn from "classnames";
import { useState } from "react";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import isNil from "lodash/isNil";

interface ITabItem {
  label: string;
  value: unknown;
}

interface ITabSelectionProps {
  tabs: Array<ITabItem>;
  onSelect: (tab: unknown) => void;
  className?: string;
  tabClassName?: string;
  activeValue?: unknown;
  alt?: boolean;
  defaultBorder?: boolean;
}

const AltTab: React.FC<{
  tab: ITabItem;
  active: boolean;
  className?: string;
  onClick: (val: unknown) => void;
}> = ({ tab, active, className, onClick }) => {
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick(tab.value);
      }}
      className={cn(
        "py-3 text-sm font-semibold text-typography-tab-passive",
        active && "border-b-[1.5px] border-black font-bold !text-black",
        className
      )}
    >
      <p className="whitespace-nowrap">{tab.label}</p>
    </button>
  );
};

const Tab: React.FC<{
  tab: ITabItem;
  active: boolean;
  className?: string;
  defaultBorder?: boolean;
  onClick: (val: unknown) => void;
}> = ({ tab, active, className, defaultBorder, onClick }) => {
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick(tab.value);
      }}
      className={cn(
        "flex justify-center items-center w-full py-3 !border-b-2 border-transparent text-sm text-typography-tab-passive font-medium hover:text-typography-headline",
        active && "!border-gray-950 !text-typography-headline font-semibold",
        defaultBorder && "border-b border-border-normal",
        className
      )}
    >
      <p className="whitespace-nowrap">{tab.label}</p>
    </button>
  );
};

const TabsSelection: React.FC<ITabSelectionProps> = ({
  tabs,
  onSelect,
  className,
  tabClassName,
  activeValue,
  alt,
  defaultBorder = true,
}) => {
  const [activeTab, setActiveTab] = useState<ITabItem>();

  const onSelectTab = (value: unknown) => {
    const tab = tabs.find((item) => isEqual(item.value, value));
    if (tab) {
      setActiveTab(tab);
      onSelect(value);
    } else {
      setActiveTab(undefined);
    }
  };

  useEffect(() => {
    if (!isNil(activeValue)) {
      onSelectTab(activeValue);
    }
  }, [activeValue]);

  const getTabProps = (tab: ITabItem) => {
    return {
      tab,
      active: isEqual(activeTab?.value, tab.value),
      onClick: onSelectTab,
      className: tabClassName,
      defaultBorder: defaultBorder,
    };
  };

  return (
    <div
      className={cn(
        "flex w-full items-center",
        alt && "w-auto gap-4",
        className
      )}
    >
      {tabs.map((tab, index) => (
        <Fragment key={tab.label + index.toString()}>
          {alt ? (
            <AltTab {...getTabProps(tab)} />
          ) : (
            <Tab {...getTabProps(tab)} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default TabsSelection;
