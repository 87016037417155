import { Comment, CommentStatusesEnum } from "models/Comment";
import useTranslation from "next-translate/useTranslation";
import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import CommentCard from "components/molecules/Comments/CommentCard";
import CtaButton from "components/atoms/CtaButton";
import Icon from "components/atoms/Icon";
import CloseIcon from "public/icons/close.svg";
import BigSpinner from "components/atoms/BigSpinner";
import "react-modern-drawer/dist/index.css";
import { TeamMember } from "types";
import { useUser } from "contexts/UserContext";
import { ICheckboxFilterOption } from "components/atoms/CheckboxFilter";
import Filter from "components/molecules/Filter";
import Input from "components/atoms/Input";
import SearchIcon from "public/icons/search.svg";
import Sort from "components/molecules/Sort";
import { IRadioOption } from "components/atoms/RadioButton";
import TabsSelection from "components/atoms/TabsSelection";
import SortIcon from "public/icons/funnel_18.svg";
import MessageIcon from "public/icons/message_16.svg";
import { useUiStateStore } from "stores/UIStateStore";

interface ICommentsDrawerProps {
  comments: Comment[];
  isOpen: boolean;
  onClose?: () => void;
  listEndRef?: any;
  loading?: boolean;
  size?: string | number;
  selectedComment: Comment | null;
  onCommentClick: (comment: Comment) => void;
  members: TeamMember[];
  refetchComments: () => void;
  editCandidate: Comment | null;
  onEdit: (comment: Comment | null) => void;
  onSubmitEdditing: (text: string) => void;
  onRemove: (comment: Comment) => void;
  onMarkAsRead: (comment: Comment) => void;
  onChangeStatus: (comment: Comment, status: CommentStatusesEnum) => void;
  onFilterChange: (opt: ICheckboxFilterOption, index: number) => void;
  search: string;
  onSearchChange: (e: any) => void;
  filter: ICheckboxFilterOption[];
  sort: IRadioOption[];
  onSortChange: (val: { name: string; options: IRadioOption[] }) => void;
  editLoading: boolean;
  filterTabs?: Array<{ label: string; value: Record<string, string> }>;
  activeFilterTab?: Record<string, string>;
  onFilterTabChange?: (val: unknown) => void;
  descriptionComponent?: React.ReactNode;
  headerHeight?: number;
}

const CommentsDrawer: React.FC<ICommentsDrawerProps> = ({
  filterTabs,
  activeFilterTab,
  onFilterTabChange,
  comments,
  isOpen,
  onClose,
  listEndRef,
  loading,
  onCommentClick,
  refetchComments,
  selectedComment,
  size = "30vw",
  editCandidate,
  filter,
  onFilterChange,
  search,
  onSearchChange,
  sort,
  onSortChange,
  descriptionComponent: DescriptionComponent,
  headerHeight = 56,
  ...rest
}) => {
  const { t } = useTranslation("common");
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { user } = useUser();

  const setRightCommentbarOpenState = useUiStateStore(
    (s) => s.setRightCommentbarOpenState
  );
  const rightCommentbarOpen = useUiStateStore((s) => s.rightCommentbarOpen);

  useEffect(() => {
    if (!isMobile) {
      setRightCommentbarOpenState(isOpen);
    } else {
      if (rightCommentbarOpen) setRightCommentbarOpenState(false);
    }
  }, [setRightCommentbarOpenState, isOpen, isMobile]);

  const handleResize = () => {
    if (window.innerWidth > 540) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener("resize", handleResize, true);
    }

    return () => {
      window.removeEventListener("resize", handleResize, true);
    };
  }, [window]);

  return (
    <Drawer
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        width: 282,
        ...(!isMobile
          ? {
              height: `calc(100vh - ${headerHeight}px)`,
              top: headerHeight,
              boxShadow: "none",
            }
          : ""),
      }}
      enableOverlay={false}
      size={size}
      direction="right"
      open={isOpen}
      onClose={onClose}
      className={!isMobile ? "border-l border-border-normal" : ""}
      zIndex={isMobile ? 9999 : 0}
    >
      <div className="p-3 border-b border-border-normal">
        <div className="flex items-center justify-between">
          <h2 className="headline-100 text-base">{t("comments")}</h2>
          {onClose && (
            <CtaButton onClick={onClose} variant="tetriary">
              <Icon
                component={CloseIcon}
                viewBox="0 0 12 12"
                width={12}
                height={12}
              />
            </CtaButton>
          )}
        </div>
        {!!DescriptionComponent && <div>{DescriptionComponent}</div>}
      </div>
      {filterTabs && (
        <TabsSelection
          tabs={filterTabs}
          activeValue={activeFilterTab}
          onSelect={onFilterTabChange!}
        />
      )}
      <div className="flex py-3 px-4 border-b border-border-normal items-center justify-start gap-1">
        <Input
          placeholder={t("search")}
          value={search}
          onChange={onSearchChange}
          icon={SearchIcon}
          className="!h-10 !px-7 !pl-14 !pr-4 !w-[174px]"
          beforeIconClassName="!top-2 !left-4"
          containerClassName="!p-0"
        />
        <div className="flex items-center">
          <Sort
            name="comments"
            options={sort}
            icon={SortIcon}
            className="p-2"
            onChange={onSortChange}
            optionsContainerClassName="!top-12"
            iconProps={{
              viewBox: "0 0 18 18",
              className: "p-2 w-[34px] h-[34px]",
            }}
          />
          <Filter
            labelClassName="text-13 font-bold whitespace-nowrap"
            checkboxClassName="w-4 mr-2"
            options={filter}
            onChange={onFilterChange}
            className="px-2.5 py-2.5"
            checkboxOptionContainerClassName="!top-10"
          />
        </div>
      </div>
      <div
        style={{
          height: `calc(100vh - ${
            filterTabs ? (isMobile ? 179 : 235) : isMobile ? 138 : 124
          }px - ${DescriptionComponent ? 32 : 0}px`,
        }}
        className="overflow-y-auto"
      >
        {!!comments.length ? (
          <ul className="w-full">
            {comments.map((el) => (
              <li key={el.id} className="border-b border-border-normal w-full">
                <CommentCard
                  active={
                    selectedComment?.repliedToId && !el.repliedToId
                      ? selectedComment?.repliedToId === el.id
                      : selectedComment?.id === el.id
                  }
                  onClick={onCommentClick}
                  comment={el}
                  user={user}
                  refetchComments={refetchComments}
                  isEdit={editCandidate?.id === el.id}
                  {...rest}
                />
              </li>
            ))}
          </ul>
        ) : (
          <div className="py-8">
            <div className="flex items-center justify-center w-full">
              <Icon
                component={MessageIcon}
                viewBox="0 0 16 16"
                width={32}
                height={32}
                className="fill-gray-50"
              />
            </div>
            <div className="p-4 pt-6 w-full text-center">
              <div className="text-sm font-bold leading-tight text-typography-title">
                {t("no_comments_yet")}
              </div>
              <div className="mt-2 text-sm leading-mormal text-typography-body-normal">
                {t("click_anywhere_in_file_to_leave_a_comment")}
              </div>
            </div>
          </div>
        )}
        <div ref={listEndRef}>
          {loading && (
            <div className="w-full h-[90px] grid place-items-center">
              <BigSpinner className="w-10 h-10" />
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default CommentsDrawer;
