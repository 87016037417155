import { IRadioOption } from "components/atoms/RadioButton";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import React, { useRef, useState } from "react";
import cn from "classnames";
import Icon from "components/atoms/Icon";
import SortIcon from "public/icons/sort_selector.svg";
import RadioButtonGroup from "./RadioButtonGroup";

interface ISortProps {
  options: IRadioOption[];
  optionsContainerClassName?: string;
  onChange: (val: { name: string; options: IRadioOption[] }) => void;
  name: string;
  className?: string;
  icon?: any;
  iconProps?: {
    viewBox: string;
    className: string;
  };
}

const Sort: React.FC<ISortProps> = ({
  options,
  onChange,
  name,
  className,
  optionsContainerClassName,
  icon,
  iconProps,
}) => {
  const [sortOpened, setSortOpened] = useState<boolean>(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    setSortOpened(false);
  });

  return (
    <div ref={ref} className={cn("relative", className)}>
      <button
        onClick={() => setSortOpened((s) => !s)}
        className="flex items-center justify-center"
      >
        <Icon
          component={icon || SortIcon}
          viewBox={iconProps?.viewBox || "0 0 20 20"}
          className={cn(
            "stroke-gray-950 hover:stroke-indigo-70",
            iconProps?.className
          )}
          width={16}
          height={16}
        />
      </button>
      {sortOpened && (
        <div
          className={cn(
            "absolute p-3 w-fit z-10 rounded-md border border-border-normal bg-white right-0 top-5",
            optionsContainerClassName
          )}
        >
          <RadioButtonGroup
            className="flex-col !items-start"
            name={name}
            buttonClassName="py-2"
            options={options}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

export default Sort;
