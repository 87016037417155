import create from "zustand";
import { persist } from "zustand/middleware";

export type UiState = {
  sidebarOpen: boolean;
  sidebarExpanded: boolean;
  rightCommentbarOpen: boolean;

  setSidebarOpenState: (open: boolean) => void;
  setSidebarExpandedState: (open: boolean) => void;
  setRightCommentbarOpenState: (open: boolean) => void;
};

const persistOpts = {
  name: "ui-state-storage",
};

export const useUiStateStore = create(
  persist<UiState>(
    (set) => ({
      sidebarOpen: false,
      sidebarExpanded: false,
      rightCommentbarOpen: false,
      setSidebarOpenState: (open) => set(() => ({ sidebarOpen: open })),
      setSidebarExpandedState: (open) => set(() => ({ sidebarExpanded: open })),
      setRightCommentbarOpenState: (open) =>
        set(() => ({ rightCommentbarOpen: open })),
    }),
    persistOpts
  )
);
