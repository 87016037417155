import React, { useState } from "react";
import { Comment, CommentStatusesEnum } from "models/Comment";
import cn from "classnames";
import Avatar from "components/atoms/Avatar";
import { getLocaleDateFormat } from "utils/getLocaleDateFormat";
import dayjs from "dayjs";
import { UserModel } from "contexts/UserContext";
import Badge from "components/atoms/Badge";
import pluralize from "pluralize";
import useTranslation from "next-translate/useTranslation";
import CommentText from "./CommentText";
import CtaButton from "components/atoms/CtaButton";
import CommentMoreActions from "./CommentMoreActions";
import CommentInput from "./CommentInput";
import { TeamMember } from "types";
import { theme } from "tailwind.config";
import Icon from "components/atoms/Icon";
import CheckmarkIcon from "public/icons/checkmark.svg";
import { get } from "lodash";

interface ICommentCardProps {
  comment: Comment;
  className?: string;
  onClick: (comment: Comment) => void;
  onEdit: (comment: Comment | null) => void;
  onSubmitEdditing: (text: string) => void;
  onRemove: (comment: Comment) => void;
  onMarkAsRead: (comment: Comment) => void;
  onChangeStatus: (comment: Comment, status: CommentStatusesEnum) => void;
  editLoading: boolean;
  isEdit?: boolean;
  active?: boolean;
  members: TeamMember[];
  user: UserModel;
  refetchComments: () => void;
}

const CommentCard: React.FC<ICommentCardProps> = ({
  comment,
  className,
  onClick,
  active,
  members,
  user,
  isEdit,
  editLoading,
  onSubmitEdditing,
  ...props
}) => {
  const { t } = useTranslation("common");
  const [editValue, setEditValue] = useState<string>(comment.text);

  const isResolved = comment.status === CommentStatusesEnum.RESOLVED;

  const commentCreator = comment.createdBy;

  const name = commentCreator.name || commentCreator.email;

  return (
    <div
      onClick={() => onClick(comment)}
      className={cn(
        "p-4 w-full flex flex-col cursor-pointer group",
        active && "bg-blue-200",
        isResolved && "bg-gray-100",
        className
      )}
    >
      <div className="flex items-center justify-between w-full mb-2">
        <div className="flex items-center">
          <div className="relative flex items-center">
            <Avatar
              fullName={name}
              image={commentCreator.photoUrl}
              avatarColor={
                isResolved
                  ? theme.extend.colors.gray[200]
                  : commentCreator.avatarColor
              }
              className="shrink-0 border-0"
            />
            {!comment.isViewed && (
              <Badge
                className={cn(
                  "absolute top-0 right-0",
                  !!comment.mentions?.find((el) => el === user?.id) &&
                    "bg-blue-500"
                )}
              />
            )}
          </div>
          {isResolved && (
            <Icon
              component={CheckmarkIcon}
              viewBox="0 0 16 14"
              width={14}
              height={14}
              className="stroke-green-700 ml-3"
            />
          )}
        </div>
        <div className="group-hover:block hidden">
          <div className="flex items-center justify-end gap-1">
            {/* <TooltipButton
              icon={
                <Icon
                  component={CheckIcon}
                  viewBox="0 0 12.71 12.71"
                  className="!fill-gray-950"
                  width={12.71}
                  height={12.71}
                />
              }
              content="Mask as resolve"
              tooltipClassName="left-1/2 -translate-x-[50%]"
              onClick={() => {
                console.log("Ok");
              }}
            /> */}
            <CommentMoreActions user={user} comment={comment} {...props} />
          </div>
        </div>
      </div>
      <div className="w-full">
        <p className="body-100-subtle text-[11px] leading-tight text-left">
          {!!comment.team ? `${comment.team.name} • ` : ""}
          {`${comment.pageName} - ${get(
            comment,
            comment.resourceData.namePath
          )}`}
        </p>
        <div className="w-full text-left flex justify-start items-center gap-1">
          <p className="body-100-bold leading-normal text-ellipsis text-[11px] text-typography-body-normal">
            {name}
          </p>
          <p className="body-100-subtle text-left text-[11px] leading-normal text-typography-body-subtle">
            {getLocaleDateFormat({
              date: dayjs(comment.createdat).toDate(),
              userLocale: user?.locale,
              params: {
                dateStyle: "short",
                timeStyle: "short",
              },
            })}
          </p>
        </div>
      </div>
      {isEdit ? (
        <div className="flex flex-col mt-2 w-full">
          <CommentInput
            value={editValue}
            onChange={(e) => {
              setEditValue(e.target.value);
            }}
            members={members}
          />
          <div className="flex self-end mt-1">
            <CtaButton
              variant="tetriary"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                setEditValue(comment.text);
                props.onEdit(null);
              }}
            >
              {t("Cancel")}
            </CtaButton>
            <CtaButton
              spinner={editLoading}
              className="ml-1"
              disabled={!editValue}
              variant="secondary"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onSubmitEdditing(editValue);
              }}
            >
              {t("edit")}
            </CtaButton>
          </div>
        </div>
      ) : (
        <CommentText text={comment.text} className="text-left !text-[11px]" />
      )}
      {!!comment.replies?.length && (
        <p
          className={cn(
            "mt-3 body-100-subtle text-xs",
            active && "text-indigo-70"
          )}
        >
          {comment.replies?.length +
            " " +
            t(pluralize("reply", comment.replies?.length)).toLowerCase()}
        </p>
      )}
    </div>
  );
};

export default CommentCard;
