import { ICheckboxFilterOption } from "components/atoms/CheckboxFilter";
import React from "react";
import FilterIcon from "public/icons/filter_24.svg";
import Icon from "components/atoms/Icon";
import CheckBox from "components/atoms/CheckBox";
import { useState } from "react";
import { useRef } from "react";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import cn from "classnames";

interface IFilterProps {
  options: ICheckboxFilterOption[];
  onChange: (opt: ICheckboxFilterOption, index: number) => void;
  className?: string;
  labelClassName?: string;
  checkboxContainerClassName?: string;
  checkboxOptionContainerClassName?: string;
  checkboxClassName?: string;
}

const Filter: React.FC<IFilterProps> = ({
  options,
  onChange,
  className,
  labelClassName,
  checkboxClassName,
  checkboxContainerClassName,
  checkboxOptionContainerClassName,
}) => {
  const [filterOpened, setFilterOpened] = useState<boolean>(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    setFilterOpened(false);
  });

  return (
    <div ref={ref} className={cn("relative", className)}>
      <button
        onClick={() => setFilterOpened((s) => !s)}
        className="flex items-center justify-center"
      >
        <Icon
          component={FilterIcon}
          viewBox="0 0 24 24"
          className="fill-gray-950 hover:fill-indigo-70"
          width={16}
          height={16}
        />
      </button>
      {filterOpened && (
        <div
          className={cn(
            "absolute p-3 z-10 rounded-md border border-border-normal bg-white right-0 top-5",
            checkboxOptionContainerClassName
          )}
        >
          {options.map((item, index) => (
            <CheckBox
              key={item.value}
              checked={item.checked}
              labelClassName={cn("capitalize", labelClassName)}
              containerClassName={cn(
                "!pb-3 last:!pb-0",
                checkboxContainerClassName
              )}
              checkboxClassName={checkboxClassName}
              onChange={(e) => {
                onChange({ ...item, checked: e.target.checked }, index);
              }}
              label={item.label}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Filter;
