import { ICheckboxFilterOption } from "components/atoms/CheckboxFilter";
import { useUser } from "contexts/UserContext";
import { Translate } from "next-translate";
import useTranslation from "next-translate/useTranslation";
import { useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import useDebouncedState from "./useDebouncedState";
import { IRadioOption } from "components/atoms/RadioButton";
import { useRouter } from "next/router";
import { getCommentsTabFilters } from "./getCommentsTabFilters";
import { CommentSubject } from "utils/constants";
import isEmpty from "lodash/isEmpty";

const getInitialFilter = (t: Translate) => [
  {
    label: t("only_mine"),
    checked: false,
    value: `filter.createdById`,
  },
  {
    label: t("only_mentioned"),
    checked: false,
    value: `filter.mentions.userId`,
  },
];

const getInitialSort = (t: Translate) => [
  {
    label: t("recent_first"),
    checked: true,
    value: "createdat:DESC",
  },
  {
    label: t("unread_first"),
    checked: false,
    value: "isViewed:ASC",
  },
  {
    label: t("mine_first"),
    checked: false,
    value: "createdByMe:DESC",
  },
  {
    label: t("mentions_first"),
    checked: false,
    value: "mentionedMe:DESC",
  },
];

export const useCommentsFiltersState = () => {
  const { t } = useTranslation("common");
  const { user } = useUser();
  const router = useRouter();

  const [activeFilterTab, setActiveFilterTab] = useState<
    Record<string, string>
  >({});

  const filterTabs = useMemo(() => {
    const tabs: Array<{ label: string; value: Record<string, string> | {} }> = [
      { label: t("All"), value: {} },
    ];

    if (router.isReady && !isEmpty(getCommentsTabFilters(router))) {
      const tabsFilters = getCommentsTabFilters(router);

      if (!isEmpty(tabsFilters)) {
        tabs.push({
          label: t("this_screen"),
          value: tabsFilters,
        });
      }
    }

    const { tvProjectId, projectId } = router.query;

    // if (tvProjectId) {
    //   tabs.push({
    //     label: t("project"),
    //     value: {
    //       "filter.subject": CommentSubject.TV_PROJECT,
    //       "filter.tvProjectId": tvProjectId,
    //     },
    //   });
    // }

    if (projectId) {
      tabs.push({
        label: t("analytic"),
        value: {
          "filter.subject": CommentSubject.PROJECT,
          "filter.projectId": projectId,
        },
      });
    }

    return tabs;
  }, [router]);

  const [filter, setFilter] = useState<ICheckboxFilterOption[]>(
    getInitialFilter(t)
  );

  const [searchValue, setSearchValue, debouncedSearchValue] = useDebouncedState(
    "",
    400
  );

  const [sort, setSort] = useState<IRadioOption[]>(getInitialSort(t));

  const onSearchChange = (e: any) => setSearchValue(e.target.value);

  const onFilterTabChange = (val: unknown) =>
    setActiveFilterTab(val as Record<string, string>);

  const onFilterChange = (option: ICheckboxFilterOption, index: number) => {
    setFilter((s) => {
      const newState = cloneDeep(s);
      newState[index] = option;

      return newState;
    });
  };

  const onSortChange = ({
    options,
  }: {
    name: string;
    options: IRadioOption[];
  }) => {
    setSort(options);
  };

  const appliedFilters = useMemo(() => {
    if (user) {
      return filter
        .filter((el) => el.checked)
        .reduce((acc, el) => ({ ...acc, [el.value]: `$eq:${user.id}` }), {});
    }

    return {};
  }, [filter, user]);

  const appliedSort = useMemo(() => {
    const option = sort.find((el) => el.checked);
    if (option) {
      return { sortBy: option.value };
    }

    return {};
  }, [sort]);

  return {
    filter,
    appliedFilters,
    filterTabs,
    activeFilterTab,
    onFilterTabChange,
    onFilterChange,
    searchValue,
    debouncedSearchValue,
    onSearchChange,
    onSortChange,
    sort,
    appliedSort,
  };
};
